const toFixedWithoutZeroes = (value: number, decimalPlaces = 2) => {
  return value.toFixed(decimalPlaces).replace(/(\.0+|0+)$/, '');
};

export function abbreaviateNumber(
  value: any,
  decimalPlaces = 2,
  abbreviations = ['', 'K', 'M', 'B', 'T'],
  tierStep: 1000 | 1024 = 1000
) {
  const number = Number(value);

  if (isNaN(number)) {
    return '-';
  }

  let tier;
  if (tierStep === 1000) {
    tier = (Math.log10(Math.abs(number)) / 3) | 0;
  } else {
    tier = (Math.log2(Math.abs(number)) / 10) | 0;
  }

  if (tier === 0) {
    return toFixedWithoutZeroes(number, decimalPlaces);
  }

  const suffix = abbreviations[tier];
  let scale;
  if (tierStep === 1000) {
    scale = Math.pow(10, tier * 3);
  } else {
    scale = Math.pow(2, tier * 10);
  }

  const scaled = number / scale;

  return toFixedWithoutZeroes(scaled, decimalPlaces) + suffix;
}

const dataSizeUnits = ['kB', 'MB', 'GB', 'TB'];

export function formatDataSize(kilobytes: any, addSpace = true) {
  return abbreaviateNumber(
    kilobytes,
    2,
    addSpace ? dataSizeUnits.map(u => ` ${u}`) : dataSizeUnits,
    1024
  );
}

export function formatNumberReporting(value: any, decimalPlaces = 2) {
  return abbreaviateNumber(value, decimalPlaces, ['', 'k', 'M', 'G', 'T', 'P', 'E']);
}
